import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AlertController, ModalController, NavController } from "@ionic/angular";
import { Observable, Observer, Subscription } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs/";
import { filter, map, switchMap } from "rxjs/operators";
import { Events } from "src/app/services/events.service";
import { AssetDetailSegmentComponent } from "../../components/segments/asset-detail/asset-detail.component";
import { AssetEditService } from "../../services/asset-edit.service";
import { AssetsService } from "../../services/assets.service";
import { AuditService } from "../../services/audit.service";
import { ErrorsService } from "../../services/errors.service";
import { InitiativeService } from "../../services/initiative.service";
import { KPISubscriptionService } from "../../services/kpisubscription.service";
import { OfflineService } from "../../services/offline.service";
import { ScopeService } from "../../services/scope.service";
import { SearchService } from "../../services/search.service";
import {
  Asset,
  ASSET_STEP_FUTURE,
  AssetType,
  AssetTypeLevel,
  Category,
  makeNewSiteReplacementStrategy,
  NotationQuestionPicture,
  ParentAsset,
  Perimeter,
  SubCategory,
} from "../../structs/assets";
import { AuditQuestion } from "../../structs/audit";
import { BrowserService } from "@services/browser.service";
import { StepsIds } from "../../structs/assets";
export class AssetAddEvent {
  nextStep: number;
  nextLabel: string;
  goNext: boolean;
  stepValid: boolean;
}

@Component({
  selector: "app-asset-add",
  templateUrl: "./asset-add.page.html",
  styleUrls: ["./asset-add.page.scss"],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class AssetAddPage implements OnInit, OnDestroy {
  /**
   * This page can act as a routed ionic Page or from a Modal. This property is used
   * to track these two modes. If it comes from routed Page, it is default to be false
   * If we want to use as a modal, call it with
   *  ```
   *    this.modalCtrl.create({component: AssetAddPage, componentProps: {
   *      runAsModalMode: true
   *    }})
   *  ```
   */
  @Input() runAsModalMode = false;
  Steps = StepsIds;

  @ViewChild(AssetDetailSegmentComponent)
  private assetDetailSegment: AssetDetailSegmentComponent;

  needAssetTypeSelector: boolean = true;
  notationTab: string = "lifecycle";
  sourceController: String = "";
  fromRoadmap: boolean = null;
  roadmapDone: boolean = false;
  isOtherNotationsValid: boolean = true;

  @Input() currentPerimeter: Perimeter = null;
  multiPerimeter: Perimeter = null;

  loading: boolean = true;
  nextEnabled: boolean = false;
  nextLabel: string = "next";
  addAnAssetText: string;

  private allowedAssetTypes: AssetType[] = [];
  @Input() parent: Asset = null;
  public otherNotationsQuestions: AuditQuestion[] = [];
  private searchText: string = ""; // To be sent to assetTypeSelector
  public perimeters: Perimeter[] = [];
  searchMode: boolean = false;

  nextLabelChanged: EventEmitter<any> = new EventEmitter<any>();
  inBrowser: boolean = true;
  @ViewChild("browserFileUpload") browserFileUpload: ElementRef;

  // True if in ionCanLeave we are going back
  private goBack: boolean = true;
  public autoSelectAssetTypeId: number = 0;
  public autoSelectSubCategoryId: number = 0;
  public autoSelectCategoryId: number = 0;

  subscriptions: Subscription[] = [];

  public activeStep: number = StepsIds.CAMERA;
  private previousSteps: number[] = [];
  private nextStep: number = 0;
  @Input() public defaultNote: number = null;
  @Input() public defaultInstallationYear: number = null;
  @Input() public nextAssetMode: boolean = false;
  private assetToCloneFrom: Asset = null;

  constructor(
    private navCtrl: NavController,
    private errors: ErrorsService,
    public assetEditService: AssetEditService,
    private assetsApi: AssetsService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private offlineApi: OfflineService,
    private auditService: AuditService,
    private scope: ScopeService,
    private event: Events,
    private searchService: SearchService,
    private kpiSubscriptionService: KPISubscriptionService,
    private initiativeService: InitiativeService,
    private router: Router,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private browserService: BrowserService
  ) {}

  ngOnInit() {
    this.inBrowser = this.browserService.inBrowser();
    let defaultLabel: string = "";
    this.route.queryParams.pipe(filter(() => !!this.router.getCurrentNavigation()?.extras)).subscribe(params => {
      if (this.router.getCurrentNavigation().extras.state) {
        this.fromRoadmap = this.router.getCurrentNavigation().extras.state.fromRoadmap;
        this.assetToCloneFrom = this.router.getCurrentNavigation().extras.state.assetToCloneFrom;
        this.currentPerimeter = this.router.getCurrentNavigation().extras.state.perimeter;
        if (this.router.getCurrentNavigation().extras.state.multiPerimeter) {
          this.multiPerimeter = this.router.getCurrentNavigation().extras.state.multiPerimeter;
          this.perimeters = this.multiPerimeter.sub_perimeters;
          if (this.perimeters.length === 1) {
            // If there's only 1 perimeter available, we pick it
            this.assetEditService.perimeterChanged(this.perimeters[0]);
          }
        }
        this.searchText = this.router.getCurrentNavigation().extras.state.searchText;
        this.sourceController = this.router.getCurrentNavigation().extras.state.sourceController;
        this.allowedAssetTypes = this.router.getCurrentNavigation().extras.state.assetTypes;
        defaultLabel = this.router.getCurrentNavigation().extras.state.defaultLabel;
        this.parent = this.router.getCurrentNavigation().extras.state.parent || null;
        this.autoSelectAssetTypeId = this.router.getCurrentNavigation().extras.state.assetTypeId || 0;
        this.autoSelectSubCategoryId = this.router.getCurrentNavigation().extras.state.subCategoryId || 0;
        this.autoSelectCategoryId = this.router.getCurrentNavigation().extras.state.categoryId || 0;
      }
    });

    if (this.fromRoadmap) {
      // By default, we allow to finish
      this.roadmapDone = true;
    }
    if (!this.multiPerimeter) {
      this.scope.getCurrentMultiPerimeter().subscribe((multiPerimeter: Perimeter) => {
        this.multiPerimeter = multiPerimeter;
        this.perimeters = multiPerimeter.sub_perimeters;
        if (this.perimeters.length === 1) {
          // If there's only 1 perimeter available, we pick it
          this.assetEditService.perimeterChanged(this.perimeters[0]);
        }
      });
    }

    this.assetEditService.setCurrentPerimeter(this.multiPerimeter, this.assetToCloneFrom);

    this.allowedAssetTypes = this.allowedAssetTypes ? this.allowedAssetTypes : [];

    if (this.assetEditService.asset && !this.assetEditService.asset.offlineId) {
      // @ts-ignore
      this.assetEditService.asset.notesPictures = {};
    }

    this.scope.getPerimeterBuilding(this.currentPerimeter).subscribe(building => {
      this.assetEditService.asset.building = building;
    });

    if (defaultLabel) {
      this.assetEditService.asset.label = defaultLabel;
      this.assetEditService.saveLabel(null);
    }

    this.offlineApi.getConfig("newSiteReplacementStrategies").subscribe((elts: any) => {
      if (elts) {
        let newSiteReplacementStrategies = elts
          .map(elt => makeNewSiteReplacementStrategy(elt))
          .filter(elt => elt.is_default);
        if (newSiteReplacementStrategies.length > 0) {
          this.assetEditService.asset.newSiteReplacementStrategy = newSiteReplacementStrategies[0];
        }
      }
    });

    // Creating a future equipment
    if (this.nextAssetMode) {
      this.activeStep = StepsIds.ASSETTYPE;
      this.assetEditService.asset.installationYear = this.defaultInstallationYear;
      this.assetEditService.asset.step = ASSET_STEP_FUTURE;
    }

    if (this.parent) {
      this.assetEditService.asset.parent = new ParentAsset(this.parent.id, this.parent.offline, this.parent.offlineId);
      // If parent the asset type is defined by the parent
      if (this.parent.assetType.level === AssetTypeLevel.LEVEL_NONE) {
        this.autoSelectAssetTypeId = this.parent.assetType.id;
      }
      if (this.assetEditService.isOwnershipTypeEnabled) {
        this.assetEditService.asset.ownershipType = this.parent.ownershipType;
      }
    } else if (this.allowedAssetTypes.length) {
      // If we limit the list of asset types : don't display the selector
      if (this.allowedAssetTypes.length === 1) {
        this.autoSelectAssetTypeId = this.allowedAssetTypes[0].id;
      }
    }

    if (this.assetToCloneFrom) {
      // We already got the nomenclature with this function: this.assetEditService.setCurrentPerimeter()
      this.needAssetTypeSelector = false;
      this.assetEditService.ownershipTypeChanged(this.assetToCloneFrom.ownershipType);
    } else if (this.autoSelectAssetTypeId || this.autoSelectSubCategoryId || this.autoSelectCategoryId) {
      // so we've come in with some defaults which means we need to go away and get
      // them and tell the asset edit service about it
      this.assetsApi.getCategories().subscribe(categories => {
        if (categories.length === 0) {
          this.translate.get("Configuration missing. Refresh the list of perimeters").subscribe(text => {
            this.errors.signalError(text);
          });
        } else {
          // deal with a lower level item being set with no higher level
          for (let i = 0; i < categories.length; i++) {
            let category: Category = categories[i];
            for (let j = 0; j < category.children.length; j++) {
              let subCategory: SubCategory = category.children[j];
              if (this.autoSelectSubCategoryId == subCategory.id) {
                this.autoSelectCategoryId = category.id;
              }
              for (let k = 0; k < subCategory.children.length; k++) {
                let assetType: AssetType = subCategory.children[k];
                if (this.autoSelectAssetTypeId == assetType.id) {
                  this.autoSelectCategoryId = category.id;
                  this.autoSelectSubCategoryId = subCategory.id;
                }
              }
            }
          }

          for (let i = 0; i < categories.length; i++) {
            let category: Category = categories[i];
            if (this.autoSelectCategoryId == category.id) {
              this.assetEditService.categoryChanged(category);

              for (let j = 0; j < category.children.length; j++) {
                let subCategory: SubCategory = category.children[j];
                if (this.autoSelectSubCategoryId == subCategory.id) {
                  this.assetEditService.subcategoryChanged(subCategory);
                  if (subCategory.children.length == 1) {
                    this.assetEditService.assettypeChanged(subCategory.children[0]);
                    // We don't need the asset type selector only if the nomenclature is
                    // fully set (if we have an assetType)
                    this.needAssetTypeSelector = false;
                    break;
                  }

                  for (let k = 0; k < subCategory.children.length; k++) {
                    let assetType: AssetType = subCategory.children[k];
                    if (this.autoSelectAssetTypeId == assetType.id) {
                      this.assetEditService.assettypeChanged(assetType);
                      // We don't need the asset type selector only if the nomenclature is
                      // fully set (if we have an assetType)
                      this.needAssetTypeSelector = false;
                      break;
                    }
                  }
                  break;
                }
              }
              break;
            }
          }
        }
      });
    } else {
      // We open the asset type selector if we don't have any pre-settings for it
      // (if we are not coming from the roadmap or not cloning an asset)
      this.needAssetTypeSelector = true;
    }

    if (this.assetToCloneFrom) {
      this.addAnAssetText = this.translate.instant("New duplicated equipment");
    } else {
      this.addAnAssetText = this.translate.instant("New asset");
    }
  }

  public getPageTitle(): string {
    let title: string;
    if (this.activeStep === StepsIds.PERIMETER) {
      title = this.assetToCloneFrom
        ? this.translate.instant("Perimeter for the duplicated equipment")
        : this.translate.instant("Choose a perimeter for the equipment");
    } else if (this.activeStep === StepsIds.ASSETTYPE) {
      title = this.translate.instant("Select the asset type");
    } else if (this.activeStep === StepsIds.PARENT) {
      title = this.translate.instant("Select a parent");
    } else if (this.activeStep === StepsIds.DETAIL && this.nextAssetMode) {
      title = this.translate.instant("Future equipment");
    } else title = this.addAnAssetText;

    return title;
  }

  public onParentSelected(parent: Asset | null, auto: boolean) {
    this.parent = parent;
    if (parent) {
      this.assetEditService.asset.parent = new ParentAsset(this.parent.id, this.parent.offline, this.parent.offlineId);
    } else {
      this.assetEditService.asset.parent = null;
    }

    // Prepare the parent to have its children automatically expanded on assets list
    this.parent && this.searchService.setAssetsChildrenToggled(this.parent, true);

    this.detailsChanged({
      nextStep: 0,
      nextLabel: "addAsset",
      goNext: auto, // If the parent has been automatically added, go next.
      stepValid: this.assetEditService.asset.parent != null,
    });
  }

  async onBackButtonClicked() {
    if (this.previousSteps.length === 0) {
      await this.navCtrl.back();
    } else {
      // Else we reactive the previous segment
      const lastIndex = this.previousSteps.length - 1;
      this.activeStep = this.previousSteps[lastIndex];
      this.previousSteps.splice(lastIndex, 1);
      if (this.activeStep === StepsIds.CAMERA) {
        this.nextEnabled = true;
      }
    }
  }

  // close this page if it is a modal
  public async closeModal() {
    await this.modalCtrl.dismiss();
  }

  ionViewDidEnter() {
    if (this.activeStep === StepsIds.DETAIL) {
      this.assetDetailSegment.ionViewDidEnter();
    }
    if (this.activeStep === StepsIds.CAMERA) {
      let nextStep = null;
      if (!this.assetEditService.getPerimeter()) {
        nextStep = StepsIds.PERIMETER;
      } else {
        nextStep = this.needAssetTypeSelector ? StepsIds.ASSETTYPE : StepsIds.DETAIL;
      }
      this.detailsChanged({
        nextStep: nextStep,
        goNext: false,
        nextLabel: "next",
        stepValid: true,
      });
    }
    this.goBack = true;
  }

  detailsChanged(event?: AssetAddEvent) {
    if (!event) {
      return;
    }
    setTimeout(() => {
      // Because of the change detection, we need to wait a bit before changing the step
      // Otherwise, the step will be changed before the animation is finished
      // The timeout is set to be 0, so it will be executed after the change detection
      this.nextStep = event.nextStep;
      this.nextLabel = event.nextLabel;
      if (event.stepValid) {
        this.nextEnabled = true;
      } else {
        this.nextEnabled = false;
      }
      if (event.goNext) {
        this.next();
      }
      this.nextLabelChanged.emit(this.nextLabel);
    });
  }

  next(nextStepDone?: Observer<boolean>) {
    if (this.nextStep === 0) {
      if (nextStepDone) {
        nextStepDone.next(true);
      }
      this.saveAsset();
    } else {
      this.previousSteps.push(this.activeStep);
      this.activeStep = this.nextStep;
      if (nextStepDone) {
        nextStepDone.next(true);
      }
    }
    this.nextEnabled = false;
  }

  private saveAsset() {
    this.assetEditService.saveNewAsset().subscribe(
      newAsset => {
        // Go to notation
        this.loading = false;
        this.roadmapDone = false; // We consider that the roadmap is not done. We need to give a note
        this.goBack = false;

        if (newAsset.notesPictures) {
          for (const [kpiId, notePictures] of Object.entries(newAsset.notesPictures)) {
            (<NotationQuestionPicture[]>notePictures).forEach(notePicture => {
              this.auditService.insertAuditQuestionPictureChange(notePicture, newAsset).subscribe();
            });
          }
        }

        let observer = of(null);
        if (this.parent) {
          observer = this.saveParent(this.parent, newAsset);
        }
        observer.subscribe(() => {
          // close
          if (this.runAsModalMode) {
            this.modalCtrl.dismiss({ asset: newAsset });
          } else {
            this.navCtrl.pop();
          }
        });
        this.event.publish("newAsset", newAsset);
      },
      err => {
        this.loading = false;
        this.errors.signalError(err);
        this.detailsChanged();
      }
    );
  }

  public pageChanged(): void {
    this.goBack = false;
  }

  public otherNotationsValidityChanged(isValid: boolean) {
    this.isOtherNotationsValid = isValid;
  }

  public notationsChanged(event) {
    const { notations } = event;
    this.assetEditService.setOtherNotation(notations);
  }

  private saveParent(parent, newAsset) {
    parent.children.push(newAsset);
    return this.offlineApi.storeAsset(parent);
  }

  // Save EnergyConsumption of an Asset and add a Change to sync
  private saveAssetEnergyConsumption(obj: {
    asset: Asset;
    energy_consumption: number;
    trajectory: number;
    perimeterConsumptionId: number;
  }): Observable<Asset> {
    const { asset, energy_consumption, trajectory, perimeterConsumptionId } = obj;
    const data = {
      asset_id: asset.id,
      energy_consumption: Math.floor(energy_consumption),
      assetOfflineId: asset.offlineId,
    };
    return this.scope
      .getCurrentMultiPerimeter()
      .pipe(
        switchMap(multiPerimeter =>
          this.initiativeService
            .updateAssetConsumption(
              multiPerimeter.id,
              asset.building.monosite_perimeter.id,
              trajectory,
              perimeterConsumptionId,
              data
            )
            .pipe(map(() => asset))
        )
      );
  }

  private isLifecycleValid(): boolean {
    return this.assetEditService.isLifecycleValid();
  }

  private showLifecycleWarning() {
    this.assetEditService.showLifecycleWarning();
  }

  private async showOtherNotationsWarning() {
    let alertDlg = await this.alertCtrl.create({
      header: this.translate.instant("Warning"),
      message: this.translate.instant("Mandatory information about other notations are missing"),
      backdropDismiss: false,
      buttons: [
        {
          text: this.translate.instant("Close"),
          handler: () => {},
        },
      ],
    });
    await alertDlg.present();
  }

  // The user clicked when the nextbutton was disabled
  async disabledClicked() {
    if (this.activeStep === StepsIds.PARENT && !this.nextEnabled) {
      const selectParentAlert = await this.alertCtrl.create({
        subHeader: this.translate.instant("Please select a parent"),
        buttons: [
          {
            text: this.translate.instant("OK"),
          },
        ],
      });
      await selectParentAlert.present();
    }
  }

  public perimeterChanged(perimeter: Perimeter) {
    this.assetEditService.perimeterChanged(perimeter);
    this.detailsChanged({
      nextStep: this.needAssetTypeSelector ? StepsIds.ASSETTYPE : StepsIds.DETAIL,
      goNext: true,
      nextLabel: "next",
      stepValid: true,
    });
  }

  public toggleSearchMode(): void {
    this.searchMode = !this.searchMode;
  }

  public expertModeChanged(event): void {
    const { user, isOn } = event;
    if (user) {
      this.assetEditService.setExpertMode(user, isOn);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
