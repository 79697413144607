/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { Observable, Subscription, of } from "rxjs";

import { AssetEditService } from "../../../services/asset-edit.service";
import { AuditService } from "../../../services/audit.service";
import { ScopeService } from "../../../services/scope.service";
import { Asset } from "../../../structs/assets";
import { AuditQuestion, AuditNotation, AuditNotationItem, NoteEvent, LEVELS_MAP } from "../../../structs/audit";
import { Investment } from "../../../structs/investments";
import { ConsistencyTestService, SuggestedLifetime } from "../../../services/consistency-test.service";
import { AssetNotationService } from "../../../services/asset-notation.service";
import { TranslateService } from "@ngx-translate/core";
import { AssetsService } from "../../../services/assets.service";
// Migration: I put the Duration class here instead of recreating the whole
// asset-duration page because it seems that the page was used only for this.
// import { Duration } from '../../../pages/asset-duration/asset-duration.component';
export class Duration {
  constructor(public value: number, public name: string) {}
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "other-notations",
  templateUrl: "./other-notations.component.html",
  styleUrls: ["./other-notations.component.scss"],
})
export class OtherNotationsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public asset: Asset;
  @Input() public investment: Investment;
  @Input() public showTechnicalState: boolean = false;
  @Input() public onlyTechnicalState: boolean = false;
  @Input() public pointlessInvestment: boolean = false;
  @Input() public addMode: boolean = false;
  @Output() public onInvestmentLifetimeChanged = new EventEmitter<any>();
  @Output() public validityChanged = new EventEmitter<boolean>();
  @Output() public changed = new EventEmitter<void>();
  @Output() public onNotationChanged = new EventEmitter<any>();
  @Output() public inconsistentData = new EventEmitter<any>();
  public readOnly: boolean = true;
  public questions: AuditQuestion[] = [];

  private auditNotation: AuditNotation;

  public editableSliderLabel: string;
  public invHasImpactOnLifetime: boolean;

  private subscriptions: Subscription[] = [];

  public durations: Duration[] = [];
  public lifetimeForDisplay: string = "";
  public suggestedLifetime: SuggestedLifetime = null;
  public remainingLifetimeAfterInvestment = "";
  public impactWarning: string;
  public showImpactWarning: boolean = false;

  private assetLifetime: number = 0;
  private rawNote: number;
  private isValid: boolean = false;

  private currentYear: number;
  hasNote: boolean = false;
  public remainingYearsLabel: string = "";
  public pointlessInvestmentMessage: string = "";
  private remainingLifetimeLabelCalculated: string = "";
  private remainingLifetimeLabelAuditor: string = "";
  public remainingLifetimeSource: string = "";
  private suggestedRemainingLifetime: number = null;
  private initializing: boolean = true;
  private replacementMode: boolean = false;
  private DEFAULT_ALPHA_COEFFICIENT: number = 4;
  private alphaCoefficient: number = this.DEFAULT_ALPHA_COEFFICIENT;

  constructor(
    private assetService: AssetsService,
    private assetEditService: AssetEditService,
    private auditApi: AuditService,
    private scope: ScopeService,
    private consistencyTestService: ConsistencyTestService,
    private assetNotationService: AssetNotationService,
    private translate: TranslateService
  ) {}

  public ngOnInit() {
    if (this.investment && this.investment.investmentType.replacement) {
      this.replacementMode = true;
    }
    this.subscriptions.push(
      this.translate.get("source.calculated").subscribe((text: string) => {
        this.remainingLifetimeLabelCalculated = text;
      }),
      this.translate.get("source.audit").subscribe((text: string) => {
        this.remainingLifetimeLabelAuditor = text;
      }),
      this.assetService.getNotationAlphaCoefficient().subscribe(notationAlphaCoefficient => {
        this.alphaCoefficient = this.asset.assetType.alphaCoefficient
          ? this.asset.assetType.alphaCoefficient
          : notationAlphaCoefficient
          ? notationAlphaCoefficient
          : this.DEFAULT_ALPHA_COEFFICIENT;
      })
    );
    setTimeout(() => {
      // When we load the previous values, we don't want these to be taken as pending
      // changes. So we don't save/send anything until the initialization is over.
      this.initializing = false;
    }, 200);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.asset || changes.investment) {
      this.init();
      this.subscriptions.push(
        this.scope.getSynthesisYear().subscribe(year => {
          this.currentYear = year;
          this.assetEditService.setAsset(this.asset, this.readOnly);
          this.assetLifetime = this.asset.expectedLifetime;
          setTimeout(() => {
            // We have to put a delay before reading the investment or it will be undefined.
            if (this.investment) {
              const translationParams = {
                date: this.investment.finalScheduleTo,
              };
              this.remainingYearsLabel = this.translate.instant(
                "Remaining lifetime (years) after the invest in {{ date }}",
                translationParams
              );
              this.pointlessInvestmentMessage = this.translate.instant(
                "Warning, the technical state in {{date}} WITHOUT investment is better than the technical state in {{date}} WITH invest.",
                translationParams
              );
              this.remainingLifetimeAfterInvestment = "" + this.investment.lifetimeAfterInvestment;
              this.invHasImpactOnLifetime = !this.investment.hasNoImpact;
              this.subscriptions.push(
                this.translate.get("Technical state after investment").subscribe(label => {
                  this.editableSliderLabel = `${label} (${this.investment.finalScheduleTo})`;
                })
              );
            } else {
              this.subscriptions.push(
                this.translate.get("Technical state").subscribe(label => {
                  this.editableSliderLabel = label;
                })
              );
            }

            this.subscriptions.push(
              this.auditApi.getAuditQuestions(this.asset, this.investment).subscribe(questions => {
                const question = questions.find(q => q.mnemonic === "technical_state");
                const item = question.items[0];
                let lifetimeAfterInvestment;
                this.rawNote = LEVELS_MAP[item.current_note];

                lifetimeAfterInvestment = this.investment ? this.investment.lifetimeAfterInvestment : 0;
                const durationDeviation = this.investment ? this.investment.durationDeviation : 0;
                if (!this.rawNote) {
                  this.subscriptions.push(
                    this.auditApi.getAuditQuestions(this.asset).subscribe(questionsAudit => {
                      const questionAudit = questionsAudit.find(q => q.mnemonic === "technical_state");
                      const itemAudit = questionAudit.items[0];
                      this.rawNote = LEVELS_MAP[itemAudit.current_note];
                      if (!this.replacementMode) {
                        this.verifyDataConsistency(this.rawNote, lifetimeAfterInvestment);
                      }
                    })
                  );
                } else {
                  this.verifyDataConsistency(this.rawNote, lifetimeAfterInvestment);
                }
              })
            );
          }, 200);

          this.durations = this.assetNotationService.createDurationsListFromExpectedDuration(this.assetLifetime);
        })
      );
    }
  }

  public noteUpdated(event: NoteEvent): void {
    const newNote = LEVELS_MAP[event.note];
    if (this.rawNote !== newNote || !this.hasNote) {
      this.rawNote = newNote;
      this.invHasImpactOnLifetime = true;
      this.hasNote = true;

      this.calculateLifetimeAfterNoteHasChanged(this.rawNote);

      const { itemId, note } = event;

      if (!this.initializing || this.addMode) {
        const noteIndex = this.auditNotation.notes.findIndex(note => note.question_item === itemId);
        if (noteIndex > -1) {
          this.auditNotation.notes[noteIndex].note = note;
          this.subscriptions.push(
            this.updateNotation().subscribe(() => {
              this.areOtherNotationsValid();
            })
          );
        }
      }
    }
  }

  public investmentLifetimeChanged(durationDeviation: number, lifetimeAfterInvestment: number): void {
    if (!this.initializing || this.addMode) {
      this.onInvestmentLifetimeChanged.emit({
        durationDeviation,
        lifetimeAfterInvestment,
      });
    }
  }

  /**
   * To make sure the user types an integer
   */
  inputNumberCheck(ev) {
    let inputNumber = parseInt(ev.target.value);
    this.remainingLifetimeAfterInvestment = "" + Math.trunc(inputNumber);
  }

  /**
   * Called when the deviation duration changed
   */
  public remainingLifetimeUpdated(remainingLifetimeText: string | number): void {
    const remainingLifetime: number = +remainingLifetimeText;
    const assetRemainingLifetime = this.asset.expectedLifetime - (this.currentYear - this.asset.installationYear);
    const durationDeviation = remainingLifetime - assetRemainingLifetime;
    this.investment.durationDeviation = durationDeviation;
    this.investment.lifetimeAfterInvestment = remainingLifetime;
    this.verifyDataConsistency(this.rawNote, remainingLifetime);
    this.investmentLifetimeChanged(durationDeviation, remainingLifetime);
    this.areOtherNotationsValid();
  }

  /**
   * Reinit duration deviation if investment has no impact on asset lifetime
   *
   * @param hasImpact
   */
  public onImpactLifetimeChanged(hasImpact: boolean): void {
    if (!hasImpact) {
      this.investment.durationDeviation = 0;
      this.investment.lifetimeAfterInvestment = this.asset.expectedLifetime;
    }
    const assetRemainingLifetime = this.asset.expectedLifetime - (this.currentYear - this.asset.installationYear);
    this.remainingLifetimeUpdated(assetRemainingLifetime);
  }

  /**
   * returns true if all mandatory questions have a note
   */
  private checkMandatoryQuestions(): boolean {
    for (let note of this.auditNotation.notes) {
      if (note.mandatory && note.note === null) {
        return false;
      }
    }
    return true;
  }

  private init(): void {
    this.subscriptions.push(
      this.scope.getSelectedPerimeter().subscribe(perimeter => (this.readOnly = perimeter.read_only)),
      this.auditApi.getAuditQuestions(this.asset, this.investment).subscribe(questions => {
        this.buildQuestions(questions);
        this.buildAuditNotation(this.questions);
        this.areOtherNotationsValid();
      })
    );
  }

  private buildQuestions(questions: AuditQuestion[]): void {
    this.questions = questions.filter(question => {
      return this.onlyTechnicalState
        ? question.mnemonic === "technical_state"
        : this.showTechnicalState || question.mnemonic !== "technical_state";
    });
  }

  private buildAuditNotation(questions: AuditQuestion[]): void {
    const notes: AuditNotationItem[] = questions.reduce((notes, question) => {
      // mandatory fields don't have default values
      let defaultValue = null;
      if (question.question_type === "on-off") {
        defaultValue = question.mandatory ? null : 1;
      } else if (question.question_type === "stars") {
        defaultValue = question.mandatory ? null : 4;
      } else if (question.question_type === "sliders") {
        defaultValue = question.mandatory ? null : 1;
      } else {
        defaultValue = question.mandatory ? null : 0;
      }
      const questionNotes = question.items.map(item => {
        const itemNote = item.current_note === null ? defaultValue : item.current_note;
        return new AuditNotationItem(item.id, itemNote, question.mandatory, []);
      });

      return [].concat(notes, questionNotes);
    }, []);

    this.auditNotation = new AuditNotation(notes);
  }

  private updateNotation(): Observable<any> {
    // Investment notation
    if (this.investment) {
      // Update notes on the investment
      this.auditNotation.notes.forEach(note => {
        this.investment.notes[note.question_item] = note.note;
      });
      if (!this.initializing || this.addMode) {
        this.onNotationChanged.emit({
          asset: this.asset,
          investment: this.investment,
          auditNotation: this.auditNotation,
        });
      }
      return of(true);
      // Asset notation
    } else {
      // Update notes on the asset
      this.auditNotation.notes.forEach(note => {
        this.asset.notes[note.question_item] = note.note;
      });
      this.changed.emit();
      // Update an existing asset
      if (this.asset.id) {
        return this.auditApi.setAuditNote(this.asset, this.auditNotation);
        // Add notation on a new asset
      } else {
        this.assetEditService.setOtherNotation(this.auditNotation);
        return of(true);
      }
    }
  }

  /**
   * The actual model doesn't take into account intermediary audits
   *
   * @param note
   * @param lifetimeAfterInvestment
   */
  private verifyDataConsistency(note: number, lifetimeAfterInvestment: number): void {
    if (this.investment) {
      this.suggestedLifetime = this.consistencyTestService.areTechnicalStateAndLifetimeConsistent(
        this.investment.finalScheduleTo, // the date of the note: we give a note at the end of the investment
        this.investment.finalScheduleTo, // the installation year: for an investment, the end of the investment
        note, // the note given by the auditor
        this.asset.assetType.expected_duration, // the theoretical duration of the asset
        lifetimeAfterInvestment, // the new lifetime after investment
        0,
        this.alphaCoefficient
      );
      if (this.hasNote && (this.invHasImpactOnLifetime || !this.investment.hasNoImpact)) {
        this.inconsistentData.emit(this.suggestedLifetime);
      } else {
        this.inconsistentData.emit(null);
      }

      if (lifetimeAfterInvestment !== this.suggestedRemainingLifetime) {
        this.remainingLifetimeSource = this.remainingLifetimeLabelAuditor;
      } else {
        this.remainingLifetimeSource = this.remainingLifetimeLabelCalculated;
      }
    }
  }

  private calculateLifetimeAfterNoteHasChanged(note) {
    if (this.asset) {
      this.scope.getSynthesisYear().subscribe(() => {
        this.suggestedRemainingLifetime = this.consistencyTestService.getEstimatedRemainingLifetime(
          this.investment.initialScheduleTo, // the date of the note: we give a note at the end of the investment
          this.investment.initialScheduleTo, // the installation year: for an investment, the end of the investment
          note, // the note given by the auditor
          this.asset.assetType.expected_duration, // the theoretical duration of the asset
          this.alphaCoefficient
        );
        if (!this.initializing || this.addMode) {
          this.remainingLifetimeUpdated(this.suggestedRemainingLifetime);
        }
        this.remainingLifetimeAfterInvestment = "" + this.investment.lifetimeAfterInvestment;
        this.areOtherNotationsValid();
      });
    }
  }

  areOtherNotationsValid() {
    this.isValid =
      this.checkMandatoryQuestions() && parseInt(this.remainingLifetimeAfterInvestment) > 0 && this.hasNote;
    if (!this.initializing || this.addMode) {
      this.validityChanged.emit(this.isValid);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
