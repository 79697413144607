import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IonSelect } from "@ionic/angular";
import { Options, LabelType } from "@angular-slider/ngx-slider";
import * as R from "ramda";
import { Observable, Subscription } from "rxjs";

import { AuditService } from "../../../services/audit.service";
import { SynthesisService } from "../../../services/synthesis.service";
import { Asset } from "../../../structs/assets";
import { Investment } from "../../../structs/investments";
import { LEVELS_MAP, NoteEvent } from "../../../structs/audit";
// import { EventService } from "src/app/services";
import { Events } from "src/app/services/events.service";

export const STATE_AVERAGE_NOTES = {
  dead: 0,
  critical: 14,
  bad: 38,
  acceptable: 62,
  good: 94,
  new: 100,
};

export const TICKS = R.values(LEVELS_MAP);
const STATE_LIMITS_COLORS = ["#0D0D0D", "#C00000", "#EF7981", "#F6C750", "#4CC08E"];
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "technical-state-input",
  templateUrl: "./technical-state-input.component.html",
  styleUrls: ["./technical-state-input.component.scss"],
})
export class TechnicalStateInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public asset: Asset;
  @Input() public investment: Investment;
  @Input() public rawNote: number = 100;
  @Input() public rawNoteFirstTime: boolean = true;
  @Input() public readOnly: boolean = false;
  @Input() public refresh: Observable<void>;
  @Input() public defaultNote: number;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onNoteChanged = new EventEmitter<NoteEvent>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onRawNoteChanged = new EventEmitter<number>();
  @ViewChild(IonSelect) technicalStateSelect: IonSelect;
  public showSlider: boolean = false;
  public note: number;
  public hasRawNote: boolean = false;
  public sliderRefresh = new EventEmitter<void>();
  public options: Options = {
    floor: 0,
    ceil: 100,
    animate: false,
    keyboardSupport: false,
    hidePointerLabels: true,
    enforceStep: false,
    ticksArray: TICKS,
    customPositionToValue: (percent: number, minVal: number, maxVal: number): number => {
      const rawValue = percent * (maxVal - minVal) + minVal;

      // Find nearest tick
      let nearest = TICKS[0];
      for (const tick of TICKS) {
        if (Math.abs(rawValue - tick) > Math.abs(rawValue - nearest)) {
          return nearest;
        }
        nearest = tick;
      }
      return nearest;
    },
    getPointerColor: this.getPointerColor,
    getTickColor: this.getStateColor,
    translate: (_value: number, label: LabelType): string => {
      if (label === LabelType.Floor) {
        return this.deadLabel;
      } else if (label === LabelType.Ceil) {
        return this.newLabel;
      }
    },
  };

  private deadLabel: string;
  private newLabel: string;
  private refreshSubscription: Subscription;
  lastAuditSentence: string;
  showLastAuditSentence: boolean = true;
  showImpactWarning: boolean = false;
  impactWarning: string;

  constructor(
    private auditApi: AuditService,
    private synthesisService: SynthesisService,
    private translate: TranslateService,
    public event: Events
  ) {}

  public ngOnInit(): void {
    this.translate.get("Dead").subscribe(deadLabel => (this.deadLabel = deadLabel));
    this.translate.get("New").subscribe(newLabel => (this.newLabel = newLabel));
    // If the asset doesn't have a previous notation, it's defaulted to null, so the ion-select
    // shows the placeholder (-)
    if (this.rawNoteFirstTime) {
      this.note = null;
    }
    if (this.investment) {
      this.impactWarning = this.translate.instant("This investment will reduce the asset's lifetime");
    } else {
      // We need the last audit user and date only if we are not rating
      // an investment impact
      this.event.subscribe("lastAuditSentence", sentence => {
        if (sentence) {
          this.lastAuditSentence = sentence;
        }
      });
      this.event.subscribe("showLastAuditSentence", show => {
        this.showLastAuditSentence = show;
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.asset || changes.investment) {
      if (this.investment) {
        this.initInvestment();
      } else {
        this.initAsset();
      }
    } else {
      this.hasRawNote = true;
      this.initRawNote();
    }

    if (changes.readOnly) {
      this.options = {
        ...this.options,
        readOnly: this.readOnly,
      };
    }

    if (changes.refresh) {
      this.unsubscribeRefresh();
      this.subsribeRefresh();
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribeRefresh();
    this.sliderRefresh.complete();
  }

  public stateClick(newValue: number): void {
    if (!this.readOnly) {
      this.note = newValue;
      this.noteChanged();
    }
  }

  public noteChanged(): void {
    if (!isNaN(this.note)) {
      // if we are rating an investment impact, we check if the new note is lower than the current note
      if (this.impactWarning && this.asset.notes[1] && this.note < LEVELS_MAP[this.asset.notes[1]]) {
        this.showImpactWarning = true;
      } else {
        this.showImpactWarning = false;
      }

      this.showSlider = true;
      this.sliderRefresh.emit();

      if (!this.hasRawNote) {
        this.auditApi.getAuditQuestions(this.asset, this.investment).subscribe(questions => {
          const question = questions.find(q => q.mnemonic === "technical_state");
          const item = question.items[0];
          this.onNoteChanged.emit({
            itemId: item.id,
            note: this.getScaledNote(),
          });
        });
      } else {
        // Raw note mode : we directly sent the note on 100 rather that the scaled note on 14
        this.onRawNoteChanged.emit(this.note);
      }
    } else {
      this.showSlider = false;
    }
  }

  private getStateColor(note: number): string {
    if (note <= 0) {
      return "#0D0D0D";
    } else if (note <= 25) {
      return "#C00000";
    } else if (note <= 50) {
      return "#F6B4B9";
    } else if (note <= 75) {
      return "#BB8809";
    } else if (note <= 99) {
      return "#B2E4CF";
    } else {
      return "#8FAADC";
    }
  }

  private getPointerColor(note: number): string {
    if (note <= 0) {
      return "#0D0D0D";
    } else if (note <= 25) {
      return "#C00000";
    } else if (note <= 50) {
      return "#EF7981";
    } else if (note <= 75) {
      return "#F6C750";
    } else if (note <= 99) {
      return "#4CC08E";
    } else {
      return "#8FAADC";
    }
  }

  private initAsset(): void {
    // display the current value of the technical state note of the asset
    // get questions config : we need to know the technical state value
    this.auditApi.getAuditQuestions(this.asset).subscribe(questions => {
      const question = questions.find(q => q.mnemonic === "technical_state");
      if (question) {
        // if technical state found
        const item = question.items[0]; // technical state has only 1 item : current year
        if (item.current_note !== null) {
          // display the current audit note
          this.note = LEVELS_MAP[item.current_note];
          // show as slider and not as dropdown
          this.showSlider = true;
          this.sliderRefresh.emit();
        } else if (this.defaultNote) {
          this.note = this.defaultNote;
          this.noteChanged();
        } else {
          // If no technical state, we open the dropdown list
          let ev = new UIEvent("UIEvent", {});
          this.technicalStateSelect.open(ev);
        }
      }
    });
  }

  private initRawNote(): void {
    // We init the value to 0 to force the user to put something
    this.note = isNaN(this.rawNote) ? 0 : this.rawNote;
    if (!this.rawNoteFirstTime) {
      this.noteChanged();
    }
    this.sliderRefresh.emit();
  }

  private initInvestment(): void {
    this.auditApi.getAuditQuestions(this.asset, this.investment).subscribe(questions => {
      const question = questions.find(q => q.mnemonic === "technical_state");
      const item = question.items[0];
      this.note = LEVELS_MAP[item.current_note];
      if (this.investment.investmentType.replacement && !this.note) {
        // When creating an investment of type replacement, the asset state is defaulted
        // to 'new'
        this.note = 100;
      }
      this.noteChanged();
      this.sliderRefresh.emit();
    });
  }

  private getScaledNote(): number {
    const note = this.note;

    for (let i = 1; i <= TICKS.length; i++) {
      if (note < LEVELS_MAP[i + 1]) {
        return i;
      }
    }

    return TICKS.length;
  }

  private subsribeRefresh(): void {
    if (this.refresh) {
      this.refreshSubscription = this.refresh.subscribe(() => this.sliderRefresh.emit());
    }
  }

  private unsubscribeRefresh(): void {
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
  }
}
