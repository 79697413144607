import * as moment from "moment";
import { deburr } from "lodash";
import { BudgetReference, BudgetReferenceWorkload } from "./assets";
import { Investment } from "./investments";

export function getBaseYears(): Array<number> {
  let currentYear: number = moment().year();
  let years: Array<number> = [];
  for (let i = 0; i < 5; i++) {
    years.push(currentYear + i);
  }
  return years;
}

export function safeEventNext(event, value) {
  try {
    event.next(value);
  } catch (err) {
    console.warn("safeEventNext error", err);
  }
}

export function calculateBudget(investment: Investment): number {
  return investment.getPrice();
}

export function calculateReferenceBudget(reference: BudgetReference): number {
  let total: number = reference.budgetPrice + reference.materialPrice + reference.additionalPrice;
  for (let i = 0; i < reference.workloads.length; i++) {
    let workload: BudgetReferenceWorkload = reference.workloads[i];
    total += workload.hourlyRate * workload.hoursNumber;
  }
  return total;
}

/**
 * return numeric value for key in localstorage or default value
 * @param key
 * @param defaultValue
 */
export function getLocalStorageNumValue(key: string, defaultValue: number): number {
  let strValue: string = localStorage.getItem(key);
  if (strValue) {
    let numValue: number = +strValue;
    if (!isNaN(numValue)) {
      return numValue;
    }
  }
  return defaultValue;
}

export function getLocalId(): string {
  let localId: string = "" + Math.round(Date.now()) + "-" + Math.random().toString(36);
  return localId.substr(0, 20);
}

export const cleanFilterString = (input: string): string => (input ? deburr(input.trim().toLowerCase()) : null);

export function setLocalStorageItem(key: string, value: any) {
  let valueAsString: string = JSON.stringify(value);
  localStorage.setItem(key, valueAsString);
}

export function getLocalStorageItem(key: string): any | null {
  let valueAsString = localStorage.getItem(key);
  if (valueAsString) {
    try {
      return JSON.parse(valueAsString);
    } catch (exc) {
      console.error("getLocalStorageItem", key, "fails to parse", valueAsString);
      console.log(exc);
    }
  }
  return null;
}

export function firstLetterUpperCase(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function firstLetterLowerCase(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function formatSearchString(text: string): string {
  // remove accent and make lower case
  return text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

/**
 * Check if the user has disabled pictures loading
 */
export function getPicturesLoadingPermission(): boolean {
  const loadPicturesLocalItem = localStorage.getItem("loadPictures");
  if (loadPicturesLocalItem === null || loadPicturesLocalItem === "true") {
    return true;
  } else {
    return false;
  }
}

export function setPicturesLoadingPermission(loadPictures: boolean) {
  if (loadPictures) {
    localStorage.setItem("loadPictures", "true");
  } else {
    localStorage.setItem("loadPictures", "false");
  }
}

/**
 *
 * @param absolutePath
 */
export function fileInfo(absolutePath: string): {
  directory: string;
  filename: string;
} {
  const directory = absolutePath.substring(0, absolutePath.lastIndexOf("/") + 1);
  const filename = absolutePath.substring(absolutePath.lastIndexOf("/") + 1);
  return { directory, filename };
}

/**
 * Ensures a path exists in an object and returns the value at that path
 * @param base The base object to traverse
 * @param path A dot-separated string representing the path to check (e.g. "foo.bar.baz")
 * @returns The value at the specified path, or undefined if any part of the path is missing
 */
export function checkObjectPath(base: any, path: string) {
  const properties = path.split(".");
  const length = properties.length;
  let value = base;
  for (const [idx, prop] of properties.entries()) {
    if (idx < length && (value === undefined || value === null)) {
      console.error("path traversal not possible", base, value);
      return;
    }
    value = value[prop];
  }
}
